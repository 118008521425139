<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg-Header6.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title align">Contact Us</h1>
        </div>
      </div>
    </div>

    <contact-us></contact-us>

    <div class="parallax-effect" style="background-image: url('img/map.jpg')"></div>
  </div>
</template>
<script>
import { ContactUs } from '@/components';

export default {
  name: 'contact',
  bodyClass: 'contact-page',
  components: {
    ContactUs
  },
};
</script>
<style>
.parallax-effect {
  /* Set a specific height */
  min-height: 500px; 
  margin-bottom: 150px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
