<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg-Header1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">J5 Logistics Services</h1>
          <h5><cite>
            <i class="now-ui-icons arrows-1_minimal-right"></i>
            <i class="now-ui-icons arrows-1_minimal-right"></i>
            <i class="now-ui-icons arrows-1_minimal-right"></i>
            Moving Ahead
            <i class="now-ui-icons arrows-1_minimal-right"></i>
            <i class="now-ui-icons arrows-1_minimal-right"></i>
            <i class="now-ui-icons arrows-1_minimal-right"></i>
          </cite></h5>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What we can offer</h2>
            <h4 class="description">
              We provide solutions by offering air , ocean freight forwarding services, customs brokerage and contract Logistics.
            </h4>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/img1.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-warning">
                  "To deliver flexible, cost effective and best-in-class freight and logistics solutions."
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/img3.jpg')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/img2.jpg')"
              ></div>
              <div>
                <h3>
                  Our Services
                </h3>
                <p>
                  Sea freight shipping with options designed to accommodate varying cargo sizes. 
                  Two of these include Full Container Load (FCL) and Less Than Container Load (LCL) shipping.
                </p>
                <p>
                  Air freight, or air cargo allows the transport of goods quickly by air.
                  Shipping cargo by air has always been a high cost-to-weight form of shipment, 
                  countered by the fact that it’s the fastest way to transport goods internationally.
                </p>
                <p>
                  Warehousing and logistics relates to the process of receiving and storing inventory 
                  efficiently and cost-effectively, so items can be easily fulfilled and shipped without 
                  slowing down the supply chain.
                </p>
                <div class="learn-button text-center">
                  <router-link class="btn btn-warning" type="button" to="/services">Learn More</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us></contact-us>    
  </div>
</template>
<script>
import { ContactUs } from '@/components';

export default {
  name: 'home',
  bodyClass: 'landing-page',
  components: {
    ContactUs
  }
};
</script>
<style>
</style>
