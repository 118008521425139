<template>
  <navbar 
    position="fixed" 
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/">
        <img src="img/logo.png" alt="Logo" style="height: 28px;"> Logistics Services
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/"
        >
          <i class="now-ui-icons shopping_shop"></i>
          <p>Home</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/services"
        >
          <i class="now-ui-icons shopping_box"></i>
          <p>Services</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/about"
        >
          <i class="now-ui-icons business_badge"></i>
          <p>About Us</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link"
          to="/contact"
        >
          <i class="now-ui-icons ui-2_chat-round"></i>
          <p>Contact Us</p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar, } from '@/components';

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
  }
};
</script>

<style scoped></style>
