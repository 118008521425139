<template>
  <div>
    <div class="page-header page-header-small" id="carousel">
      <el-carousel height="60vh">
          <el-carousel-item>
            <img class="header-img bg-filter" src="img/bg-Header2.jpg" alt="First slide" />
            <!-- <div class="carousel-caption d-none d-md-block">
                <h5>Efficiency</h5>
            </div> -->
            </el-carousel-item>
            <el-carousel-item>
            <img class="header-img bg-filter" src="img/bg-Header3.jpg" alt="Second slide" />
            <!-- <div class="carousel-caption d-none d-md-block">
                <h5>Flexibility</h5>
            </div> -->
            </el-carousel-item>
            <el-carousel-item>
            <img class="header-img bg-filter" src="img/bg-Header4.jpg" alt="Third slide" />
            <!-- <div class="carousel-caption d-none d-md-block">
                <h5>Commitment to customers</h5>
            </div> -->
          </el-carousel-item>
      </el-carousel>
      <div class="content-center">
        <div class="container">
          <h1 class="title">SERVICES</h1>
          <h5>Moving made easy</h5>
        </div>
      </div>
    </div>

    <div class="starter-page">
      <div class="section text-center">
        <div class="card card-services" style="width: 20rem;">
          <img class="card-img-top" src="img/img4.jpg" alt="Airplane">
          <div class="card-body">
            <h4 class="card-title">Air Freight</h4>
            <p class="card-text">
              The best valuable option for coordinating time sensitive shipments
            </p>
          </div>
        </div>
        <div class="card card-services" style="width: 20rem;">
          <img class="card-img-top" src="img/img5.jpg" alt="Cargo Ship">
          <div class="card-body">
            <h4 class="card-title">Sea Freight</h4>
            <p class="card-text">
              Full Container Load (FCL) and Less Than Container Load (LCL) shipping
            </p>
          </div>
        </div>
        <div class="card card-services" style="width: 20rem;">
          <img class="card-img-top" src="img/img6.jpg" alt="Cargo Truck">
          <div class="card-body">
            <h4 class="card-title">Road Freight</h4>
            <p class="card-text">
              The physical process of transporting cargo by road using motor vehicles
            </p>
          </div>
        </div>
        <div class="card card-services" style="width: 20rem;">
          <img class="card-img-top" src="img/img7.jpg" alt="Warehouse">
          <div class="card-body">
            <h4 class="card-title">Warehousing</h4>
            <p class="card-text">
              Also includes but not limited to assembly and distribution logistics.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h1 class="title">Other Services Offered</h1>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-4">
                  <div class="card bg-dark text-white o-services">
                    <img class="card-img" src="img/img8.jpg" alt="Card image">
                    <div class="card-img-overlay">
                      <h2 class="card-text">Tariff Consultancy</h2>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="card bg-dark text-white o-services">
                    <img class="card-img" src="img/img9.jpg" alt="Card image">
                    <div class="card-img-overlay">
                      <h2 class="card-text">Project Cargo</h2>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="card bg-dark text-white o-services">
                    <img class="card-img" src="img/img10.jpg" alt="Card image">
                    <div class="card-img-overlay">
                      <h2 class="card-text">Cargo Insurance</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-4">
                  <div class="card bg-dark text-white o-services">
                    <img class="card-img" src="img/img11.jpg" alt="Card image">
                    <div class="card-img-overlay">
                      <h2 class="card-text">Export & Import Customs Clearance</h2>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="card bg-dark text-white o-services">
                    <img class="card-img" src="img/img12.jpg" alt="Card image">
                    <div class="card-img-overlay">
                      <h2 class="card-text">Packing & Crating</h2>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4">
                  <div class="card bg-dark text-white o-services">
                    <img class="card-img" src="img/img13.jpg" alt="Card image">
                    <div class="card-img-overlay">
                      <h2 class="card-title">Door to Door Services</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="parallax-effect" style="background-image: url('img/bg1.jpg')"></div>
      </div>
    </div>

    <contact-us></contact-us>

  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';
import { ContactUs } from '@/components';

export default {
  name: 'services',
  bodyClass: 'services-page',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    ContactUs
  }
  
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}

.header-img {
  position:absolute;
  background-size:cover;
  background-position: center center;
  object-fit:cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.bg-filter {
  filter: blur(5px);
}
.txt-left {
  text-align: justify;
}
.content-left {
  position: absolute;
  top: 45%;
  left: 33%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.card-services {
  margin-left: 10px;
  margin-right: 10px;
}
.card-services > img {
  height: 180px;
}
.parallax-effect {
  /* Set a specific height */
  min-height: 500px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.o-services > img {
  filter: blur(2px);
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
}
.card-img-overlay > h2 {
  margin: auto;
  font-weight: bold;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
               0px 8px 13px rgba(0,0,0,0.1),
               0px 18px 23px rgba(0,0,0,0.1);
}
</style>
