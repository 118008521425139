<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg-Header5.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="photo-container">
          <img src="img/logo.png" alt="" />
        </div>
        <h2 class="title">J5 Logistics Services</h2>
        <h5><cite>
          <i class="now-ui-icons arrows-1_minimal-right"></i>
          <i class="now-ui-icons arrows-1_minimal-right"></i>
          <i class="now-ui-icons arrows-1_minimal-right"></i>
          Moving Ahead
          <i class="now-ui-icons arrows-1_minimal-right"></i>
          <i class="now-ui-icons arrows-1_minimal-right"></i>
          <i class="now-ui-icons arrows-1_minimal-right"></i>  
        </cite></h5>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="button-container">
          <router-link class="btn btn-warning btn-round btn-lg" type="button" to="/contact">Contact Us</router-link>
        </div>
        <h2 class="title">About us</h2>
        <div class="custom-font">
          <h5 class="description">
            J5 Logistics Services was established since February 2021. Providing solutions by offering
            air, ocean freight forwarding services, customs brokerage and contract Logistics.
          </h5>
          <h5 class="description">
            Due to J5 Logistics Services’ relationships with local suppliers and air/ocean partners globally,
            we can provide customers with the best pricing and routing options. We take great pride in being a part of various global networks.
          </h5>
          <h5 class="description">
            At J5 Logistics Services we pursue relationship selling and forming long-term positive business relationships with clients.
          </h5>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <h3 class="title text-center">Our Mission</h3>
            <div class="custom-font">
              <h5 class="description">
                To operate a disciplined company in the freight forwarding and logistics sector leading the way to improvement for prompt, efficient and top-notch customer service.
              </h5>
            </div>
          </div>
          <div class="col-md-6 ml-auto mr-auto">
            <h3 class="title text-center">Our Vision</h3>
            <div class="custom-font">
              <h5 class="description">
                To deliver flexible, cost effective and best-in-class freight and logistics solutions.
              </h5>
            </div>
          </div>
        </div>
        <hr />
        <h3 class="title">Core Values</h3>
        <div class="row mb-5">
          <div class="col-md-4 ml-auto mr-auto">
            <div class="custom-icon">
              <i slot="label" class="now-ui-icons sport_user-run"></i>
            </div>
            <p class="description core-values-title">Efficiency</p>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <div class="custom-icon">
              <i slot="label" class="now-ui-icons arrows-1_refresh-69"></i>
            </div>
            <p class="description core-values-title">Flexibility</p>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <div class="custom-icon">
              <i slot="label" class="now-ui-icons ui-2_like"></i>
            </div>
            <p class="description core-values-title">Excellence</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 ml-auto mr-auto">
            <div class="custom-icon">
              <i slot="label" class="now-ui-icons business_globe"></i>
            </div>
            <p class="description core-values-title">Teamwork</p>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <div class="custom-icon">
              <i slot="label" class="now-ui-icons transportation_bus-front-12"></i>
            </div>
            <p class="description core-values-title">Reliability</p>
          </div>          
          <div class="col-md-4 ml-auto mr-auto">
            <div class="custom-icon">
              <i slot="label" class="now-ui-icons emoticons_satisfied"></i>
            </div>
            <p class="description core-values-title">Commitment to customers</p>
          </div>
        </div>
      </div>
    </div>

    <contact-us></contact-us>

  </div>
</template>
<script>
import { ContactUs } from '@/components';

export default {
  name: 'profile',
  bodyClass: 'profile-page',
  components: {
    ContactUs
  },
};
</script>
<style>
.custom-icon {
  background-color: #FFB236;
  margin: auto;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  padding: 0;
  max-width: 80px;
  min-width: auto;
  opacity: 0.8;
  font-weight: 400;
  transition: 0.3s;
}

.custom-icon i {
  line-height: 80px;
  display: block;
  font-size: 30px;
  transition: 0.3s;
}

.custom-icon i:hover {
  transform: scale(1.5);
}

.custom-icon:hover {
  opacity: 1;
}

.core-values-title {
  margin-top: 10px;
  font-size: 20px;
  color: #000 !important;
}

.custom-font h5 {
  color: #000;
  font-weight: 500;
}
</style>
