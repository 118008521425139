<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="contact">
        <i class="now-ui-icons tech_mobile"></i> <a href="tel:+639171539525">0917-1539525</a>
        <i class="now-ui-icons ui-1_email-85 ml-2"></i> <a href="mailto:jc.j5logistics@gmail.com">jc.j5logistics@gmail.com</a>
      </div>
      <div class="copyright">
        J5 Logistics Company &copy; 2021
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  }
};
</script>
<style>
@media screen and (max-width: 600px) {
  .contact {
    visibility: hidden;
    clear: both;
    margin: auto;
    width: 28%;
    display: none;
  }
}

.contact {
  display: inline;
  font-size: 0.8571em;
}
</style>
