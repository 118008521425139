<template>
    <div>
      <div class="section text-center">          
        <div class="container">
          <h2 class="title">Get in touch with us!</h2>
          <h4>Please use the contact information below, or send an email:</h4>
          <div>  
              <p><i class="now-ui-icons location_pin"></i> Address: 2nd Level 738 M.L. Quezon St., Cabancalan Mandaue City 6014</p>
              <p><i class="now-ui-icons ui-1_send"></i> Telephone Number: <a href="tel:0324212512">(032) 421-2512</a></p>
              <p><i class="now-ui-icons tech_mobile"></i> Mobile Number: <a href="tel:+639171539525">0917-1539525</a></p>
              <p><i class="now-ui-icons ui-1_email-85"></i> Email: <a href="mailto:jc.j5logistics@gmail.com">jc.j5logistics@gmail.com</a></p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

export default {
  name: 'helper',
  bodyClass: 'contact-page',
};
</script>
<style></style>
